import { StorageResultApiService } from './sqlite/storage-result-api.service';
import { HttpClient, HttpParams } from "@angular/common/http"; 
import { Injectable } from "@angular/core";
import { firstValueFrom } from 'rxjs';
import { StorageService } from "./storage.service";

export type ResponseType = 'json' | 'text' | 'blob' | 'arraybuffer';
export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

@Injectable({
  providedIn: 'root',
})
export class HttpClientProvider {
  private get defaultCacheExpirationDate(): Date {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7); // 7 días
    // expirationDate.setMinutes(expirationDate.getMinutes() + 1); // 1 minuto prueba
    return expirationDate;
  }

  constructor(
    public http: HttpClient,
    public storage: StorageService,
    public storageResultApi: StorageResultApiService,
  ) {
  }

  async getRequest<T>(endpoint: string, options: {
    params?: any, 
    responseType?: ResponseType, 
    cache?: boolean, 
    resetCache?: boolean, 
    customCacheExpiration?: Date 
  } = {}): Promise<any> {
    const { params = {}, responseType = 'json', cache = false, resetCache = false, customCacheExpiration = this.defaultCacheExpirationDate } = options;
    const cacheKey = `cache_${JSON.stringify(endpoint)}_${JSON.stringify(params)}`;
    const httpOptions: any = {
      responseType,
      params: new HttpParams({ fromObject: params })
    };

    if (!resetCache && cache) {
      const cachedData = await this.storageResultApi.getDataByCode(cacheKey);
      if(cachedData) {
        let _cachedData = JSON.parse(cachedData.data);
        if (_cachedData && new Date() < new Date(_cachedData.expiration)) {
          return _cachedData.response;
        }
      }
    }

    try {
      const response = await firstValueFrom(this.http.get<T>(endpoint, httpOptions));
      if (cache) {
        const data = { response, expiration: customCacheExpiration };
        await this.storageResultApi.setData(cacheKey, JSON.stringify(data));
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  async postRequest<T>(endpoint: string, options: { 
    body: any, 
    params?: any, 
    responseType?: ResponseType, 
    cache?: boolean, 
    resetCache?: boolean, 
    customCacheExpiration?: Date 
  }): Promise<any> {
    const { body, params = {}, responseType = 'json', cache = false, resetCache = false, customCacheExpiration = this.defaultCacheExpirationDate } = options;
    const cacheKey = `cache_${JSON.stringify(endpoint)}_${JSON.stringify(body)}_${JSON.stringify(params)}`;
    const httpOptions: any = {
      responseType,
      params: new HttpParams({ fromObject: params })
    };
    if (!resetCache && cache) {
      // const cachedData = await this.storage.get(cacheKey);
      const cachedData = await this.storageResultApi.getDataByCode(cacheKey);
      if(cachedData) {
        let _cachedData = JSON.parse(cachedData.data);
        if (_cachedData && new Date() < new Date(_cachedData.expiration)) {
          return _cachedData.response;
        }
      }
    }

    try {
      const response = await firstValueFrom(this.http.post<T>(endpoint, body, httpOptions));
      if (cache) {
        // await this.storage.set(cacheKey, { response, expiration: customCacheExpiration });
        const data = { response, expiration: customCacheExpiration };
        await this.storageResultApi.setData(cacheKey, JSON.stringify(data));
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  async putRequest<T>(endpoint: string, options: { 
    body: any, 
    params?: any, 
    responseType?: ResponseType 
  }): Promise<any> {
    const { body, params = {}, responseType = 'json' } = options;
    const httpOptions: any = {
      responseType,
      params: new HttpParams({ fromObject: params })
    };

    try {
      return await firstValueFrom(this.http.put<T>(endpoint, body, httpOptions));
    } catch(err) {
      throw err;
    }
  }

  async deleteRequest(endpoint: string): Promise<any> {
    try {
      return await firstValueFrom(this.http.delete(endpoint));
    } catch(err) {
      throw err;
    }
  }

  async customRequest(endpoint: string, options: { 
    method: HttpMethod, 
    body?: any, 
    params?: any, 
    responseType?: ResponseType 
  }): Promise<any> {
    const { method, body = {}, params = {}, responseType = 'json' } = options;
    const httpOptions: any = {
      responseType,
      params: new HttpParams({ fromObject: params })
    };

    let request: Promise<any>;

    switch (method) {
      case 'GET':
        request = firstValueFrom(this.http.get(endpoint, { ...httpOptions, responseType: responseType as 'json' }));
        break;
      case 'POST':
        request = firstValueFrom(this.http.post(endpoint, body, { ...httpOptions, responseType: responseType as 'json' }));
        break;
      case 'PUT':
        request = firstValueFrom(this.http.put(endpoint, body, { ...httpOptions, responseType: responseType as 'json' }));
        break;
      case 'DELETE':
        request = firstValueFrom(this.http.delete(endpoint, { ...httpOptions, responseType: responseType as 'json' }));
        break;
      default:
        throw new Error(`Unsupported request method: ${method}`);
    }

    try {
      return await request;
    } catch(err) {
      throw err;
    }
  }

  async multipleRequest(requests: Array<{ 
    id: number, 
    url: string, 
    method: HttpMethod, 
    body?: any, 
    params?: any, 
    responseType?: ResponseType 
  }>): Promise<any[]> {
    const promises = requests.map(async (req) => {
      const options: any = {
        responseType: req.responseType || 'json',
        params: new HttpParams({ fromObject: req.params || {} })
      };

      try {
        let response;
        switch (req.method) {
          case 'GET':
            response = await firstValueFrom(this.http.get(req.url, options));
            break;
          case 'POST':
            response = await firstValueFrom(this.http.post(req.url, req.body, options));
            break;
          case 'PUT':
            response = await firstValueFrom(this.http.put(req.url, req.body, options));
            break;
          case 'DELETE':
            response = await firstValueFrom(this.http.delete(req.url, options));
            break;
          default:
            throw new Error(`Unsupported request method: ${req.method}`);
        }
        return {
          id: req.id,
          status: 'success',
          data: response
        };
      } catch (err) {
        return {
          id: req.id,
          status: 'error',
          data: (err || 'Unknown error')
        };
      }
    });

    // Esperar a que todas las promesas se resuelvan
    return Promise.all(promises);
  }
}