import { Injector } from "@angular/core";
import { ENV } from "../interfaces/interfaces";
import { EncryptionService } from "../services/encryption.service";

export function getEnvironment(): ENV {
    let _x = '3fa85f64c2f9e0c53fa85f64c2f9e0c5', _y = '3fa85f64c2f9e0c5';
    const injector = Injector.create({providers: [{provide: EncryptionService, useClass: EncryptionService, deps: []}]});
    const encryptionService = injector.get(EncryptionService);

    const encryptedEnv: ENV = encryptionService.decrypt(window.selfEnv, _x, _y);
    return encryptedEnv;
}