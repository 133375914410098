export class DataUpgradeStatements {
    dataUpgrades = [
        {
            toVersion: 1,
            statements: [
                `CREATE TABLE IF NOT EXISTS data(
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    code TEXT NOT NULL UNIQUE,
                    active INTEGER DEFAULT 1,
                    data TEXT NOT NULL
                );`
            ]
        },
    ]
}