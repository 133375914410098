//import { Capacitor } from '@capacitor/core';
/**
 * SQLiteConnection Class
 */
export class SQLiteConnection {
  constructor(sqlite) {
    this.sqlite = sqlite;
    this._connectionDict = new Map();
  }
  async initWebStore() {
    try {
      await this.sqlite.initWebStore();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async saveToStore(database) {
    try {
      await this.sqlite.saveToStore({
        database
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async saveToLocalDisk(database) {
    try {
      await this.sqlite.saveToLocalDisk({
        database
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async getFromLocalDiskToStore(overwrite) {
    const mOverwrite = overwrite != null ? overwrite : true;
    try {
      await this.sqlite.getFromLocalDiskToStore({
        overwrite: mOverwrite
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async echo(value) {
    try {
      const res = await this.sqlite.echo({
        value
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isSecretStored() {
    try {
      const res = await this.sqlite.isSecretStored();
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async setEncryptionSecret(passphrase) {
    try {
      await this.sqlite.setEncryptionSecret({
        passphrase: passphrase
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async changeEncryptionSecret(passphrase, oldpassphrase) {
    try {
      await this.sqlite.changeEncryptionSecret({
        passphrase: passphrase,
        oldpassphrase: oldpassphrase
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async clearEncryptionSecret() {
    try {
      await this.sqlite.clearEncryptionSecret();
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async checkEncryptionSecret(passphrase) {
    try {
      const res = await this.sqlite.checkEncryptionSecret({
        passphrase: passphrase
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async addUpgradeStatement(database, upgrade) {
    try {
      if (database.endsWith('.db')) database = database.slice(0, -3);
      await this.sqlite.addUpgradeStatement({
        database,
        upgrade
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createConnection(database, encrypted, mode, version, readonly) {
    try {
      if (database.endsWith('.db')) database = database.slice(0, -3);
      await this.sqlite.createConnection({
        database,
        encrypted,
        mode,
        version,
        readonly
      });
      const conn = new SQLiteDBConnection(database, readonly, this.sqlite);
      const connName = readonly ? `RO_${database}` : `RW_${database}`;
      this._connectionDict.set(connName, conn);
      /*
      console.log(`*** in createConnection connectionDict: ***`)
      this._connectionDict.forEach((connection, key) => {
        console.log(`Key: ${key}, Value: ${connection}`);
      });
      */
      return Promise.resolve(conn);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async closeConnection(database, readonly) {
    try {
      if (database.endsWith('.db')) database = database.slice(0, -3);
      await this.sqlite.closeConnection({
        database,
        readonly
      });
      const connName = readonly ? `RO_${database}` : `RW_${database}`;
      this._connectionDict.delete(connName);
      /*      console.log(`*** in closeConnection connectionDict: ***`)
      this._connectionDict.forEach((connection, key) => {
        console.log(`Key: ${key}, Value: ${connection}`);
      });
      */
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isConnection(database, readonly) {
    const res = {};
    if (database.endsWith('.db')) database = database.slice(0, -3);
    const connName = readonly ? `RO_${database}` : `RW_${database}`;
    res.result = this._connectionDict.has(connName);
    return Promise.resolve(res);
  }
  async retrieveConnection(database, readonly) {
    if (database.endsWith('.db')) database = database.slice(0, -3);
    const connName = readonly ? `RO_${database}` : `RW_${database}`;
    if (this._connectionDict.has(connName)) {
      const conn = this._connectionDict.get(connName);
      if (typeof conn != 'undefined') return Promise.resolve(conn);else {
        return Promise.reject(`Connection ${database} is undefined`);
      }
    } else {
      return Promise.reject(`Connection ${database} does not exist`);
    }
  }
  async getNCDatabasePath(path, database) {
    try {
      const databasePath = await this.sqlite.getNCDatabasePath({
        path,
        database
      });
      return Promise.resolve(databasePath);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createNCConnection(databasePath, version) {
    try {
      await this.sqlite.createNCConnection({
        databasePath,
        version
      });
      const conn = new SQLiteDBConnection(databasePath, true, this.sqlite);
      const connName = `RO_${databasePath})`;
      this._connectionDict.set(connName, conn);
      return Promise.resolve(conn);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async closeNCConnection(databasePath) {
    try {
      await this.sqlite.closeNCConnection({
        databasePath
      });
      const connName = `RO_${databasePath})`;
      this._connectionDict.delete(connName);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isNCConnection(databasePath) {
    const res = {};
    const connName = `RO_${databasePath})`;
    res.result = this._connectionDict.has(connName);
    return Promise.resolve(res);
  }
  async retrieveNCConnection(databasePath) {
    if (this._connectionDict.has(databasePath)) {
      const connName = `RO_${databasePath})`;
      const conn = this._connectionDict.get(connName);
      if (typeof conn != 'undefined') return Promise.resolve(conn);else {
        return Promise.reject(`Connection ${databasePath} is undefined`);
      }
    } else {
      return Promise.reject(`Connection ${databasePath} does not exist`);
    }
  }
  async isNCDatabase(databasePath) {
    try {
      const res = await this.sqlite.isNCDatabase({
        databasePath
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async retrieveAllConnections() {
    return this._connectionDict;
  }
  async closeAllConnections() {
    const delDict = new Map();
    try {
      /*      console.log(`*** in closeAllConnections connectionDict: ***`)
      this._connectionDict.forEach((connection, key) => {
        console.log(`Key: ${key}, Value: ${connection}`);
      });
      */
      for (const key of this._connectionDict.keys()) {
        const database = key.substring(3);
        const readonly = key.substring(0, 3) === 'RO_' ? true : false;
        await this.sqlite.closeConnection({
          database,
          readonly
        });
        delDict.set(key, null);
      }
      for (const key of delDict.keys()) {
        this._connectionDict.delete(key);
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async checkConnectionsConsistency() {
    try {
      const keys = [...this._connectionDict.keys()];
      const openModes = [];
      const dbNames = [];
      for (const key of keys) {
        openModes.push(key.substring(0, 2));
        dbNames.push(key.substring(3));
      }
      const res = await this.sqlite.checkConnectionsConsistency({
        dbNames: dbNames,
        openModes: openModes
      });
      if (!res.result) this._connectionDict = new Map();
      return Promise.resolve(res);
    } catch (err) {
      this._connectionDict = new Map();
      return Promise.reject(err);
    }
  }
  async importFromJson(jsonstring) {
    try {
      const ret = await this.sqlite.importFromJson({
        jsonstring: jsonstring
      });
      return Promise.resolve(ret);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isJsonValid(jsonstring) {
    try {
      const ret = await this.sqlite.isJsonValid({
        jsonstring: jsonstring
      });
      return Promise.resolve(ret);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async copyFromAssets(overwrite) {
    const mOverwrite = overwrite != null ? overwrite : true;
    try {
      await this.sqlite.copyFromAssets({
        overwrite: mOverwrite
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async getFromHTTPRequest(url, overwrite) {
    const mOverwrite = overwrite != null ? overwrite : true;
    try {
      await this.sqlite.getFromHTTPRequest({
        url,
        overwrite: mOverwrite
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isDatabaseEncrypted(database) {
    if (database.endsWith('.db')) database = database.slice(0, -3);
    try {
      const res = await this.sqlite.isDatabaseEncrypted({
        database: database
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isInConfigEncryption() {
    try {
      const res = await this.sqlite.isInConfigEncryption();
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isInConfigBiometricAuth() {
    try {
      const res = await this.sqlite.isInConfigBiometricAuth();
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isDatabase(database) {
    if (database.endsWith('.db')) database = database.slice(0, -3);
    try {
      const res = await this.sqlite.isDatabase({
        database: database
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async getDatabaseList() {
    try {
      const res = await this.sqlite.getDatabaseList();
      const values = res.values;
      values.sort();
      const ret = {
        values: values
      };
      return Promise.resolve(ret);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async getMigratableDbList(folderPath) {
    const path = folderPath ? folderPath : 'default';
    try {
      const res = await this.sqlite.getMigratableDbList({
        folderPath: path
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async addSQLiteSuffix(folderPath, dbNameList) {
    const path = folderPath ? folderPath : 'default';
    const dbList = dbNameList ? dbNameList : [];
    try {
      const res = await this.sqlite.addSQLiteSuffix({
        folderPath: path,
        dbNameList: dbList
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteOldDatabases(folderPath, dbNameList) {
    const path = folderPath ? folderPath : 'default';
    const dbList = dbNameList ? dbNameList : [];
    try {
      const res = await this.sqlite.deleteOldDatabases({
        folderPath: path,
        dbNameList: dbList
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async moveDatabasesAndAddSuffix(folderPath, dbNameList) {
    const path = folderPath ? folderPath : 'default';
    const dbList = dbNameList ? dbNameList : [];
    return this.sqlite.moveDatabasesAndAddSuffix({
      folderPath: path,
      dbNameList: dbList
    });
  }
}
/**
 * SQLiteDBConnection Class
 */
export class SQLiteDBConnection {
  constructor(dbName, readonly, sqlite) {
    this.dbName = dbName;
    this.readonly = readonly;
    this.sqlite = sqlite;
  }
  getConnectionDBName() {
    return this.dbName;
  }
  getConnectionReadOnly() {
    return this.readonly;
  }
  async open() {
    try {
      await this.sqlite.open({
        database: this.dbName,
        readonly: this.readonly
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async close() {
    try {
      await this.sqlite.close({
        database: this.dbName,
        readonly: this.readonly
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async beginTransaction() {
    try {
      const changes = await this.sqlite.beginTransaction({
        database: this.dbName
      });
      return Promise.resolve(changes);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async commitTransaction() {
    try {
      const changes = await this.sqlite.commitTransaction({
        database: this.dbName
      });
      return Promise.resolve(changes);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async rollbackTransaction() {
    try {
      const changes = await this.sqlite.rollbackTransaction({
        database: this.dbName
      });
      return Promise.resolve(changes);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isTransactionActive() {
    try {
      const result = await this.sqlite.isTransactionActive({
        database: this.dbName
      });
      return Promise.resolve(result);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async loadExtension(path) {
    try {
      await this.sqlite.loadExtension({
        database: this.dbName,
        path: path,
        readonly: this.readonly
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async enableLoadExtension(toggle) {
    try {
      await this.sqlite.enableLoadExtension({
        database: this.dbName,
        toggle: toggle,
        readonly: this.readonly
      });
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async getUrl() {
    try {
      const res = await this.sqlite.getUrl({
        database: this.dbName,
        readonly: this.readonly
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async getVersion() {
    try {
      const version = await this.sqlite.getVersion({
        database: this.dbName,
        readonly: this.readonly
      });
      return Promise.resolve(version);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async getTableList() {
    try {
      const res = await this.sqlite.getTableList({
        database: this.dbName,
        readonly: this.readonly
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async execute(statements, transaction = true, isSQL92 = true) {
    try {
      if (!this.readonly) {
        const res = await this.sqlite.execute({
          database: this.dbName,
          statements: statements,
          transaction: transaction,
          readonly: false,
          isSQL92: isSQL92
        });
        return Promise.resolve(res);
      } else {
        return Promise.reject('not allowed in read-only mode');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async query(statement, values, isSQL92 = true) {
    let res;
    try {
      if (values && values.length > 0) {
        res = await this.sqlite.query({
          database: this.dbName,
          statement: statement,
          values: values,
          readonly: this.readonly,
          isSQL92: true
        });
      } else {
        res = await this.sqlite.query({
          database: this.dbName,
          statement: statement,
          values: [],
          readonly: this.readonly,
          isSQL92: isSQL92
        });
      }
      // reorder rows for ios
      res = await this.reorderRows(res);
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async run(statement, values, transaction = true, returnMode = 'no', isSQL92 = true) {
    let res;
    try {
      if (!this.readonly) {
        if (values && values.length > 0) {
          res = await this.sqlite.run({
            database: this.dbName,
            statement: statement,
            values: values,
            transaction: transaction,
            readonly: false,
            returnMode: returnMode,
            isSQL92: true
          });
        } else {
          res = await this.sqlite.run({
            database: this.dbName,
            statement: statement,
            values: [],
            transaction: transaction,
            readonly: false,
            returnMode: returnMode,
            isSQL92: isSQL92
          });
        }
        // reorder rows for ios
        res.changes = await this.reorderRows(res.changes);
        return Promise.resolve(res);
      } else {
        return Promise.reject('not allowed in read-only mode');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async executeSet(set, transaction = true, returnMode = 'no', isSQL92 = true) {
    let res;
    try {
      if (!this.readonly) {
        res = await this.sqlite.executeSet({
          database: this.dbName,
          set: set,
          transaction: transaction,
          readonly: false,
          returnMode: returnMode,
          isSQL92: isSQL92
        });
        //      }
        // reorder rows for ios
        res.changes = await this.reorderRows(res.changes);
        return Promise.resolve(res);
      } else {
        return Promise.reject('not allowed in read-only mode');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isExists() {
    try {
      const res = await this.sqlite.isDBExists({
        database: this.dbName,
        readonly: this.readonly
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isTable(table) {
    try {
      const res = await this.sqlite.isTableExists({
        database: this.dbName,
        table: table,
        readonly: this.readonly
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async isDBOpen() {
    try {
      const res = await this.sqlite.isDBOpen({
        database: this.dbName,
        readonly: this.readonly
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async delete() {
    try {
      if (!this.readonly) {
        await this.sqlite.deleteDatabase({
          database: this.dbName,
          readonly: false
        });
        return Promise.resolve();
      } else {
        return Promise.reject('not allowed in read-only mode');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async createSyncTable() {
    try {
      if (!this.readonly) {
        const res = await this.sqlite.createSyncTable({
          database: this.dbName,
          readonly: false
        });
        return Promise.resolve(res);
      } else {
        return Promise.reject('not allowed in read-only mode');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async setSyncDate(syncdate) {
    try {
      if (!this.readonly) {
        await this.sqlite.setSyncDate({
          database: this.dbName,
          syncdate: syncdate,
          readonly: false
        });
        return Promise.resolve();
      } else {
        return Promise.reject('not allowed in read-only mode');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async getSyncDate() {
    try {
      const res = await this.sqlite.getSyncDate({
        database: this.dbName,
        readonly: this.readonly
      });
      let retDate = '';
      if (res.syncDate > 0) retDate = new Date(res.syncDate * 1000).toISOString();
      return Promise.resolve(retDate);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async exportToJson(mode, encrypted = false) {
    try {
      const res = await this.sqlite.exportToJson({
        database: this.dbName,
        jsonexportmode: mode,
        readonly: this.readonly,
        encrypted: encrypted
      });
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async deleteExportedRows() {
    try {
      if (!this.readonly) {
        await this.sqlite.deleteExportedRows({
          database: this.dbName,
          readonly: false
        });
        return Promise.resolve();
      } else {
        return Promise.reject('not allowed in read-only mode');
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }
  async executeTransaction(txn, isSQL92 = true) {
    let changes = 0;
    let isActive = false;
    if (!this.readonly) {
      await this.sqlite.beginTransaction({
        database: this.dbName
      });
      isActive = await this.sqlite.isTransactionActive({
        database: this.dbName
      });
      if (!isActive) {
        return Promise.reject('After Begin Transaction, no transaction active');
      }
      try {
        for (const task of txn) {
          if (typeof task !== 'object' || !('statement' in task)) {
            throw new Error('Error a task.statement must be provided');
          }
          if ('values' in task && task.values && task.values.length > 0) {
            const retMode = task.statement.toUpperCase().includes('RETURNING') ? 'all' : 'no';
            const ret = await this.sqlite.run({
              database: this.dbName,
              statement: task.statement,
              values: task.values,
              transaction: false,
              readonly: false,
              returnMode: retMode,
              isSQL92: isSQL92
            });
            if (ret.changes.changes < 0) {
              throw new Error('Error in transaction method run ');
            }
            changes += ret.changes.changes;
          } else {
            const ret = await this.sqlite.execute({
              database: this.dbName,
              statements: task.statement,
              transaction: false,
              readonly: false
            });
            if (ret.changes.changes < 0) {
              throw new Error('Error in transaction method execute ');
            }
            changes += ret.changes.changes;
          }
        }
        // commit
        const retC = await this.sqlite.commitTransaction({
          database: this.dbName
        });
        changes += retC.changes.changes;
        const retChanges = {
          changes: {
            changes: changes
          }
        };
        return Promise.resolve(retChanges);
      } catch (err) {
        // rollback
        const msg = err.message ? err.message : err;
        await this.sqlite.rollbackTransaction({
          database: this.dbName
        });
        return Promise.reject(msg);
      }
    } else {
      return Promise.reject('not allowed in read-only mode');
    }
  }
  async reorderRows(res) {
    const retRes = res;
    if (res?.values && typeof res.values[0] === 'object') {
      if (Object.keys(res.values[0]).includes('ios_columns')) {
        const columnList = res.values[0]['ios_columns'];
        const iosRes = [];
        for (let i = 1; i < res.values.length; i++) {
          const rowJson = res.values[i];
          const resRowJson = {};
          for (const item of columnList) {
            resRowJson[item] = rowJson[item];
          }
          iosRes.push(resRowJson);
        }
        retRes['values'] = iosRes;
      }
    }
    return Promise.resolve(retRes);
  }
}
