import { NotificationService } from './notification.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthInterface, AuthUserModel } from '../interfaces/interfaces';
import { SettingsProvider } from './settings';
import { BehaviorSubject, Observable, catchError, from, of, switchMap } from 'rxjs';
import { HttpClientProvider } from './http-client';
import { LoadingService } from './loading.service';
import { NavController } from '@ionic/angular';
import { StorageService } from './storage.service';
import { StorageResultApiService } from './sqlite/storage-result-api.service';

export type UserType = AuthInterface | null;

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	// env = getEnvironment();

	private _user = new BehaviorSubject<UserType>(null);
  	user$ = this._user.asObservable();
	set setUser(value: UserType) {
		this._user.next(value);
	}
	get getUser() {
		return this._user.value;
	}

	headers:any = null;
	
	constructor(
		private httpClient: HttpClient,
		private navCtrl: NavController,
		private loading: LoadingService,
		private http: HttpClientProvider,
		private storage: StorageService,
		private toast: NotificationService,
		public storageResultApi: StorageResultApiService,
	) {
	}

	async getToken() {
		let auth: any = await this.storage.get('auth');
		return this._getToken(auth) || null;
	}

	private _getToken(auth:AuthInterface){
		if(auth) {
			return auth.access_token;
		} else {
			return null;
		}
	}

	async updateToken(token: any) {
		let auth:any = await this.storage.get('auth');
		if(auth) {
			const authNew:UserType = { ...auth, access_token: token };
			await this.storage.set('auth', authNew);
			await this.getUserByToken().then();
		} else {
			await this.logout('expired');
		}
	}

	async updateUserStorage(user: AuthInterface['user']) {
		let auth:any = await this.storage.get('auth');
		if(auth) {
			const authNew:UserType = { ...auth, user: user };
			await this.storage.set('auth', authNew);
		} else {
			await this.logout('expired');
		}
	}

	async logout(type?: any) {
		// localStorage.clear();
		if(type&&type == 'expired') {
			await this.logoutData();
			this.toast.error('Sesión expirada', 'Debe iniciar sesión nuevamente');
		} else {
			this.loading.loadingShow(2);
			this.http.postRequest(SettingsProvider.authLogout(), {body: {}}).then( async (res) => {
				this.loading.dismiss();
				await this.logoutData();
			}).catch(async (error) => {
				this.loading.dismiss();
				console.log(error);
				await this.logoutData();
			});
		}
	}

	async logoutData() {
		await this.dataRemove();
		this.navCtrl.navigateRoot('/auth');
	}

	async dataRemove() {
		await this.storage.clear();
		await this.storageResultApi.clearData();
		this.setUser = null;
	}

	async successfullOAuthAuthorization(token: any, expires_in: string, user: any, guest: boolean = false) {
		let _user:AuthUserModel = {
			id: user['id'] ?? 0,
			name: user['name'] ?? undefined,
			email: user['email'] ?? undefined,
			image: user['image'] ?? undefined,
			cellphone: user['cellphone'] ?? undefined,
			birthdate: user['birthdate'] ?? undefined,

			membershipCurrent: user['memberships_current'] ?? undefined,
			memberships: user['memberships'] ?? [],
		}
		const auth:AuthInterface = { access_token: token, expires_in: expires_in, user: _user, guest: guest };
		await this.storage.set('auth', auth);
	}

	async getAuth(): Promise<UserType> {
		let auth:UserType = await this.storage.get('auth') ?? null;
		return auth;
	}

	async getUserByToken(): Promise<UserType> {
		try {
			const auth = await this.getAuth();
			if (!auth || !auth.access_token) {
				this.setUser = null;
				return null;
			}
			if(auth.guest) {
				this.setUser = auth;
				return await this.userData(auth);
			}
	  
			const data = await this._getUserByToken();
			if (data['data']) {
				let _data = data['data'];
				let userData: AuthInterface['user'] = {
					id: _data['id'] ?? 0,
					name: _data['name'] ?? undefined,
					email: _data['email'] ?? undefined,
					image: _data['image'] ?? undefined,
					cellphone: _data['cellphone'] ?? undefined,
					birthdate: _data['birthdate'] ?? undefined,

					membershipCurrent: _data['memberships_current'] ?? undefined,
					memberships: _data['memberships'] ?? [],
				};
				let currentUser: UserType = { ...auth, user: userData };
				this.setUser = currentUser;
				await this.updateUserStorage(userData);
				return await this.userData(currentUser);
			}
	  
			this.setUser = null;
			return await this.userData(null);
		} catch (err) {
			console.log('errorAuth', err);
			this.setUser = null;
			return await this.userData(null);
		}
	}
	
	private async _getUserByToken(): Promise<any> {
		return this.http.getRequest(SettingsProvider.getAccount());
	}

	async userData(user?: any) {
		if (!user) {
		  	await this.logout();
		}
		return user;
	}

	async setPushTokenNotification(token: string, update:boolean = true) {
		await this.storage.set('pushToken', token);
		if(update) await this.updateUserPushToken(token);
	}
	
	async getPushTokenNotification() {
		let data = await this.storage.get('pushToken');
		return data;
	}

	private async updateUserPushToken(token :any) {
        await this.http.postRequest(SettingsProvider.getUrl('update-user-token'), {
			body: token
		}).then();
    }

	async getGeneralData() {
		// await this.http.getRequest(SettingsProvider.url('update-user-token')).then(result => {
		// 	console.log(result);
		// });
		// console.log(this.getUser);
		return new Promise<void>((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, 1000);
		});
	}
}
