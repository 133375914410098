import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';


@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private _storage: Storage | null = null;

  constructor(
    private storage: Storage
  ) {
    this.init();
  }

  async init() {
    if(!this._storage) {
      this._storage = await this.storage.create();
    }
  }
  
  async set(key: string, value: any) {
    await this._storage?.set(key, value);
  }

  // Función para obtener un valor del almacenamiento
  async get(key: string) {
    return await this._storage?.get(key);
  }

  // Función para eliminar un valor del almacenamiento
  async remove(key: string) {
    await this._storage?.remove(key);
  }

  // Función para limpiar el almacenamiento
  async clear() {
    await this._storage?.clear();
  }
}