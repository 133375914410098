import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { Injectable} from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { DataUpgradeStatements } from './upgrades/data.upgrade.statements';
import { RestultApi } from './models/result-api';
import { BehaviorSubject, Observable } from 'rxjs';
import { DbnameVersionService } from './dbname.version.service';

@Injectable()
export class StorageResultApiService {

    public dataList: BehaviorSubject<RestultApi[]> = new BehaviorSubject<RestultApi[]>([]);
    private databaseName: string = "";
    private uUpdStmts: DataUpgradeStatements = new DataUpgradeStatements();
    private versionUpgrades;
    private loadToVersion;
    private db!: SQLiteDBConnection;
    private isDataReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private sqliteService: SQLiteService,
        private dbVerService: DbnameVersionService,
    ) {
        this.versionUpgrades = this.uUpdStmts.dataUpgrades;
        this.loadToVersion = this.versionUpgrades[this.versionUpgrades.length-1].toVersion;
    }

    async initializeDatabase(dbName: string) {
        this.databaseName = dbName;
        // create upgrade statements
        await this.sqliteService.addUpgradeStatement({ 
            database: this.databaseName,
            upgrade: this.versionUpgrades
        });
        // create and/or open the database
        this.db = await this.sqliteService.openDatabase(
            this.databaseName,
            false,
            'no-encryption',
            this.loadToVersion,
            false
        );
        this.dbVerService.set(this.databaseName, this.loadToVersion);
        console.log(this.db);

        await this.getAllData();
    }
    // Current database state
    dataState() {
        return this.isDataReady.asObservable();
    }

    fetchData(): Observable<RestultApi[]> {
        return this.dataList.asObservable();
    }

    async loadAllData() {
        const allData: RestultApi[]= (await this.db.query('SELECT * FROM data;')).values as RestultApi[];
        console.log(allData);
        this.dataList.next(allData);
    }
    // CRUD Operations
    async getAllData() {
        await this.loadAllData();
        this.isDataReady.next(true);
    }

    async addData(code: string, data: string) {
        const sql = `INSERT INTO data (code, data) VALUES (?, ?);`;
        await this.db.run(sql, [code, data]);
        await this.getAllData();
    }

    async setData(code: string, data: string) {
        const sql = `INSERT OR REPLACE INTO data (code, data) VALUES (?, ?);`;
        await this.db.run(sql, [code, data]);
        await this.getAllData();
    }

    async updateDataById(id: string, active: number) {
        const sql = `UPDATE data SET active=${active} WHERE id=${id}`;
        await this.db.run(sql);
        await this.getAllData();
    }

    async deleteDataById(id: string) {
        const sql = `DELETE FROM data WHERE id=${id}`;
        await this.db.run(sql);
        await this.getAllData();
    }

    async updateDataByCode(code: string, active: number) {
        const sql = `UPDATE data SET active=${active} WHERE code=${code}`;
        await this.db.run(sql);
        await this.getAllData();
    }

    async deleteDataByCode(code: string) {
        const sql = `DELETE FROM data WHERE code=${code}`;
        await this.db.run(sql);
        await this.getAllData();
    }

    async getDataById(id: string) {
        const sql = `SELECT * FROM data WHERE id=${id}`;
        const result = await this.db.run(sql);
        return result.changes?.values?.length ? result.changes?.values[0] : null;
        // const sql = `SELECT FROM data WHERE id = ?`;
        // const result = await this.db.query(sql, [id]);
        // return result?.values.length ? result.values[0] : null;
    }
    
    async getDataByCode(code: string): Promise<RestultApi|null> {
        const sql = `SELECT * FROM data WHERE code = ?`;
        const result = await this.db.query(sql, [code]);
        return result?.values?.length ? result.values[0] : null;
    }

    async clearData() {
        const sql = `DELETE FROM data;`;
        await this.db.run(sql);
        // await this.getAllData(); // Update data
    }
}